<template>
    <div class="scanner-container">
        <div>
            <video poster="data:image/gif,AAAA" ref="scanner">
                <v-alert icon="error" prominent text dense type="error" class="my-12"> <b>Erro:</b> Não foi possivel </v-alert>
            </video>
            <div class="overlay-element" v-show="!isLoading"></div>
            <div class="laser" v-show="!isLoading"></div>
        </div>
    </div>
</template>

<script>
import { BrowserMultiFormatReader } from "@zxing/library";

export default {
    data() {
        return {
            isLoading: true,
            codeReader: new BrowserMultiFormatReader(),
            isMediaStreamAPISupported:
                navigator &&
                navigator.mediaDevices &&
                "enumerateDevices" in navigator.mediaDevices
        };
    },

    mounted() {
        if (!this.isMediaStreamAPISupported) {
            this.$emit("error", "Not suported");
            return;
        }

        this.start();
        this.$refs.scanner.oncanplay = event => {
            console.log(event)
            this.isLoading = false;
            this.$emit("loaded");
        };
    },

    beforeDestroy() {
        this.codeReader.reset();
    },

    methods: {
        start() {
            this.codeReader.decodeFromVideoDevice(
                undefined,
                this.$refs.scanner,
                (result,) => {
                    if (result) {
                        this.$emit("decode", result.text);
                    }
                }
            );
        }
    }
};
</script>

<style scoped>
video {
    max-width: 100%;
    max-height: 100%;
}

.scanner-container {
    position: relative;
}

.overlay-element {
    position: absolute;
    top: 0;
    width: 100%;
    height: 99%;
    background: rgba(30, 30, 30, 0.5);

    -webkit-clip-path: polygon(0% 0%,
            0% 100%,
            20% 100%,
            20% 20%,
            80% 20%,
            80% 80%,
            20% 80%,
            20% 100%,
            100% 100%,
            100% 0%);
    clip-path: polygon(0% 0%,
            0% 100%,
            20% 100%,
            20% 20%,
            80% 20%,
            80% 80%,
            20% 80%,
            20% 100%,
            100% 100%,
            100% 0%);
}

.laser {
    width: 60%;
    margin-left: 20%;
    background-color: tomato;
    height: 1px;
    position: absolute;
    top: 40%;
    z-index: 2;
    box-shadow: 0 0 4px red;
    -webkit-animation: scanning 2s infinite;
    animation: scanning 2s infinite;
}

@-webkit-keyframes scanning {
    50% {
        -webkit-transform: translateY(75px);
        transform: translateY(75px);
    }
}

@keyframes scanning {
    50% {
        -webkit-transform: translateY(75px);
        transform: translateY(75px);
    }
}
</style>
