<template>
    <v-dialog v-model="show" @keydown.esc="onCancel()" persistent max-width="800">
        <v-card class="fill-height" fluid>
            <v-toolbar dark color="primary">
                <v-btn icon dark @click="onCancel">
                    <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title> {{ $t("Escaneie o código") }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="text-center pa-8" v-if="show">
                <v-progress-circular v-if="loading" size="140" width="12" color="primary" indeterminate/>
                <v-alert icon="error" prominent text dense type="error" class="my-12" v-if="errorMessage"> <b>Erro:</b> {{ errorMessage }} </v-alert>
                <!-- <div>
                    <p>{{ error }}</p>
                </div> -->
                <!-- <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded" @error="onError" /> -->
                <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded" v-if="manual == false" />

                <v-text-field class="mt-12 mb-0 pb-0" :label="$t('Inserir código manualmente')" v-model="manualText" v-on:keyup.enter="sendManual" clearable outlined
                    ref="manualTextField" />
                <v-btn @click="sendManual" color="error" class="mt-0 pt-0" :disabled="!manualText"> Enviar Manualmente </v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>


<script>
import StreamBarcodeReader from "@/components/core/StreamBarcodeReader";
import { beep } from "@/helpers/Beep";

export default {
    components: { StreamBarcodeReader },

    props: {
        show: {
            type: Boolean,
            required: true,
        },
        numberDigits: {
            type: Number,
            default: 0,
        },
        manual: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        show: {
            immediate: true,
            handler() {
                this.focus();
                this.$nextTick(() => {
                    this.focus();
                });
            },
        },
    },

    data() {
        return {
            beep,
            dialog: false,
            errorMessage: null,
            loading: false,
            manualText: null,
        };
    },

    mounted() {
        if (this.manual == false) {
            this.errorMessage = "Permissão de uso da câmera pelo usuário negada";
        }

        this.focus();
    },

    methods: {
        focus() {
            if (this.$refs.manualTextField) {
                this.$refs.manualTextField.focus();
            }
        },
        emitEvent(code) {
            this.beep();
            if (this.numberDigits > 0 && code.length != this.numberDigits) {
                this.manualText = code;
            } else {
                this.$emit("onRead", code);
            }
        },
        sendManual() {
            this.beep();
            this.$emit("onRead", this.manualText);
            this.manualText = "";
        },
        onCancel() {
            this.$emit("close");
        },
        onLoaded() {
            this.errorMessage = null;
            this.focus();
        },
        onDecode(decodedString) {
            this.emitEvent(decodedString);
        },
        onError() {
            this.errorMessage = "Permissão de uso da câmera pelo usuário negada";
        },
    },
};
</script>